import React, { useState } from 'react';
import { Button, Box, Typography, CircularProgress } from '@mui/material';
import Compressor from 'compressorjs';

const UploadAndDownloadPage = () => {
  const [uploadResult, setUploadResult] = useState(null);
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false); // 新增的状态用于控制加载指示器

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setLoading(true); // 开始上传时显示加载指示器

      new Compressor(file, {
        quality: 0.6, // 调整压缩质量
        success(compressedResult) {
          setFileName(compressedResult.name);
          
          // 创建表单数据并附加压缩后的图片
          const formData = new FormData();
          formData.append('file', compressedResult, compressedResult.name);

          // 使用 fetch API 上传压缩后的图片到服务器
          fetch('https://stardatemine.online:8005/upload', { // 确保此URL与后端Flask应用一致
            method: 'POST',
            body: formData,
          })
          .then(response => {
            setLoading(false); // 处理完毕，隐藏加载指示器

            if (response.ok) {
              return response.blob();
            }
            throw new Error('Upload failed');
          })
          .then(blob => {
            // 创建一个URL用于下载或显示处理后的图片
            const downloadUrl = window.URL.createObjectURL(blob);
            setUploadResult(downloadUrl);
          })
          .catch(error => {
            console.error('Upload error:', error);
            setUploadResult(null);
            setLoading(false); // 处理出错，隐藏加载指示器
          });
        },
        error(err) {
          console.error('Compression error:', err.message);
          setUploadResult(null);
          setLoading(false); // 压缩出错，隐藏加载指示器
        },
      });
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" height="100vh" justifyContent="center">
      <Typography variant="h4" component="div" sx={{ marginBottom: '20px', fontSize: '2rem' }}>
        图片上传与下载
      </Typography>

      {/* 图片上传和压缩按钮 */}
      <Button
        variant="contained"
        component="label"
        sx={{
          marginBottom: '20px',
          padding: '10px 40px',
          fontSize: '1rem',
          minWidth: '300px',
          '&:hover': {
            backgroundColor: 'lightblue',
            color: 'black',
          },
        }}
      >
        上传并压缩图片
        <input
          type="file"
          accept="image/*"
          hidden
          onChange={handleFileChange}
        />
      </Button>

      {/* 加载指示器 */}
      {loading && (
        <Box mt={2}>
          <CircularProgress />
          <Typography variant="body2" component="div" sx={{ marginTop: '10px', color: 'gray' }}>
            正在处理，请稍候...
          </Typography>
        </Box>
      )}

      {/* 显示上传结果 */}
      {uploadResult && (
        <Box mt={4}>
          <Typography variant="body1" component="div" sx={{ fontSize: '1rem', color: 'gray' }}>
            上传成功！点击下方链接查看或下载图片：
          </Typography>
          <a href={uploadResult} download={fileName} target="_blank" rel="noopener noreferrer">
            点击这里查看图片
          </a>
        </Box>
      )}
    </Box>
  );
};

export default UploadAndDownloadPage;
