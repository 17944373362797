// // ReportViewer.js
// import React from 'react';
// import { useParams } from 'react-router-dom';

// const ReportViewer = () => {
//   const { reportUrl } = useParams();
//   // Assuming `reportUrl` is already encoded and is a part of the URL path
//   // No need to reconstruct the URL here, just decode it
//   const decodedUrl = decodeURIComponent(reportUrl);
  
//   return (
//     <iframe src={decodedUrl} width="100%" height="610px" title="Report Viewer" style={{ border: 'none' }}></iframe>
//   );
// };

// export default ReportViewer;
// src/ReportViewer.js
// src/ReportViewer.js
import React from 'react';
import { useParams } from 'react-router-dom';

const ReportViewer = () => {
  const { reportUrl } = useParams();
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <iframe src={reportUrl} title="Report Viewer" style={{ width: '100%', height: '100%', border: 'none' }} />
    </div>
  );
};

export default ReportViewer;
