import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link as RouterLink, Navigate,useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Box, Button, Typography, Container } from '@mui/material';
import LoginPage from './LoginPage';
import HomePage from './HomePage';
import FeishuAuthCallback from './FeishuAuthCallback'
import './App.css';
import LogoutButton from './LogoutButton'; 
import {  useAuth } from './AuthContext';
import SupersetDashboard from './SupersetDashboard';
import SupersetDashboard2 from './SupersetDashboard-2';
import ReportList from './ReportList';
import ReportViewer from './ReportViewer';
import GrafanaDashboard from './GrafanaDashboard'; 
import UploadAndDownloadPage from './UploadAndDownloadPage';


function App() {
  // 使用状态钩子来动态管理登录状态 
  const { isLoggedIn, logout, setIsLoggedIn } = useAuth(); 
  //const navigate = useNavigate();


//   useEffect(() => {
//     // 使用AuthContext中的setIsLoggedIn根据localStorage更新状态
//     const loggedIn = localStorage.getItem('isLoggedIn') === 'true';
//     setIsLoggedIn(loggedIn);
//   }, [setIsLoggedIn]);
    
  useEffect(() => {
  // 移除 setIsLoggedIn 的调用
  // 直接根据 localStorage 来决定是否需要执行登录或登出操作
}, []);

//   const handleLogout = () => {
//     localStorage.removeItem('isLoggedIn');
//     logout(); // 调用AuthContext中的logout方法
//     navigate('/login', { replace: true });  
//   };
    
    
  return (

    <Router>
      <AppBar position="static" sx={{ 
          borderRadius: 2, boxShadow: 6,
          '.MuiToolbar-root': {
              flexWrap: 'wrap',
          },
          '.MuiButton-root': {
              fontSize: '0.7rem',
              margin: '5px',
          },
          '.MuiTypography-body2': {
              fontSize: '0.5rem',
          },
          '@media (max-width: 600px)': {
              '.MuiTypography-h6': {
                  fontSize: '0.9rem',
              },
          }
      }}>
    <Toolbar>
      <Box display="flex" flexGrow={1} justifyContent="space-between" alignItems="center" flexWrap="wrap">
        <Box display="flex" alignItems="center">
          <img src="/logo.png" alt="Logo" style={{ height: '40px', marginRight: '10px', borderRadius: '50%' }} />
          <Button color="inherit" component={RouterLink} to="/" sx={{ margin: '0 10px' }}>
            主页
          </Button>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h6" component="div">
            个人数据分享
          </Typography>
          <Typography variant="subtitle2" component="div">
            Neo‘s Data Assistant
          </Typography>
        </Box>
        {isLoggedIn ? (
          <LogoutButton /> // 使用 LogoutButton 组件
        ) : (
          <Button
            color="inherit"
            onClick={() => {
              const appID = 'cli_a576baef2e3ad00c';
              const redirectURI = encodeURIComponent('https://stardatemine.online/feishu/callback');
              const state = 'YOUR_UNIQUE_STATE'; // 确保这个状态值唯一且难以预测，用于防止 CSRF 攻击
              window.location.href = `https://open.feishu.cn/open-apis/authen/v1/index?redirect_uri=${redirectURI}&app_id=${appID}&state=${state}`;
            }}
          >
            授权登录
          </Button>
        )}
      </Box>
    </Toolbar>
  </AppBar>

      <Container>
        <Routes>
          <Route path="/login" element={<LoginPage setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/dashboard1" element={<SupersetDashboard />} />
          <Route path="/dashboard2" element={<SupersetDashboard2 />} />     
          <Route path="/app" element={<HomePage />} />
              <Route path="/ReportList" element={<ReportList />} />
          <Route path="/report/:reportUrl" element={<ReportViewer />} /> 
          <Route path="/grafa" element={<GrafanaDashboard />} />
              <Route path="/upload-download" element={<UploadAndDownloadPage />} />
          <Route path="/" element={<Navigate replace to="/app" />} />
              
        </Routes>

      </Container>
            <Box component="footer" sx={{ 
              position: 'fixed',
              left: 0,
              bottom: 0,
              width: '100%',
              textAlign: 'center',
              py: 1, // 减小上下内边距
              backgroundColor: 'background.paper',
              borderTop: '1px solid',
              borderColor: 'divider',
            }}>
              <Typography variant="body2" color="textSecondary" sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                fontSize: '0.75rem', // 减小字体大小
              }}>
                <a
                  href="https://beian.miit.gov.cn/"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  style={{ 
                    margin: '0px', // 减小外边距
                    textDecoration: 'none', 
                    color: 'inherit' 
                  }}
                  className="slide"
                >
                  鄂ICP备2024042550号-1
                </a>
               <span style={{ margin: '0 8px' }}> </span>
               <a href="https://beian.mps.gov.cn/#/query/webSearch?code=11010502054901" 
                    rel="noreferrer" 
                    target="_blank" 
                  style={{ 
                    margin: '0px', // 减小外边距
                    textDecoration: 'none', 
                    color: 'inherit' 
                  }}
                  className="slide"
                >京公网安备11010502054901</a>

              </Typography>

    <Typography variant="body2" color="textSecondary" sx={{ fontSize: '0.75rem', mt: 1 }}>
      © 2024  Neo liuwuniu  All rights reserved.
    </Typography>
            </Box>

    </Router>

  );
}
export default App;