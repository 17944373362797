// GrafanaDashboard.js
import React, { useEffect, useRef } from 'react';

const GrafanaDashboard = () => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const updateIframeScale = () => {
      if (iframeRef.current) {
        const isMobile = window.innerWidth <= 768;
//         if (isMobile) {
//           iframeRef.current.classList.remove('desktop-center');
//           iframeRef.current.classList.add('mobile-right');
//         } else {
//           iframeRef.current.classList.remove('mobile-right');
//           iframeRef.current.classList.add('desktop-center');
//         }
      }
    };

    window.addEventListener('resize', updateIframeScale);
    updateIframeScale();

    return () => {
      window.removeEventListener('resize', updateIframeScale);
    };
  }, []);

  return (
    <div className="dashboard-container2" style={{ width: '100%', height: '100vh' }}>
      <iframe
        ref={iframeRef}
        src="https://stardatemine.online:8033/d/rYdddlPWk/node-exporter-full?orgId=1&refresh=1m&from=1719973503717&to=1720059903717"
        style={{ width: '100%', height: '100%', border: '0', overflow: 'hidden' }}
        sandbox="allow-scripts allow-same-origin allow-forms"
      ></iframe>
    </div>
  );
};

const styles = `
  @media (max-width: 480px) {
    .dashboard-container iframe {
      min-width: 480px; 
    }
  }
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default GrafanaDashboard;