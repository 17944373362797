import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // 确保路径正确
import { Button } from '@mui/material';

const LogoutButton = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    logout(); // 调用 AuthContext 中的 logout 方法
    navigate('/login', { replace: true });
  };

  return (
    <Button color="inherit" onClick={handleLogout}>
      退出登录
    </Button>
  );
};

export default LogoutButton;
