import React, { useEffect, useRef } from 'react';
import { embedDashboard } from '@superset-ui/embedded-sdk';

function SupersetDashboard2() {
  const dashboardContainerRef = useRef(null);
  const isMobile = window.innerWidth < 480;
  const iframeRef = useRef(null); // 创建一个ref来引用iframe
    


  useEffect(() => {
    const fetchGuestTokenFromBackend = async () => {
      try {
        const response = await fetch('https://stardatemine.online:8005/api/get-superset-token2');
        const data = await response.json();
        if (!response.ok || !data.guest_token) {
          throw new Error('Failed to fetch guest token');
        }
        return data.guest_token;
      } catch (error) {
        console.error('Error fetching guest token:', error);
        // 这里处理错误
      }
    };

    const doEmbed = async () => {
      try {
        const guestToken = await fetchGuestTokenFromBackend();
        if (dashboardContainerRef.current && guestToken) {
          embedDashboard({
            id: "3c881204-b3b2-46ab-ad21-bb6d1a82d694",
            supersetDomain: 'https://stardatemine.online:8089',
            mountPoint: dashboardContainerRef.current,
            fetchGuestToken: () => Promise.resolve(guestToken),
            dashboardUiConfig: {
              hideTitle: true,
              hideChartControls: true, // 隐藏图表控件
              filters: {
                visible: false, // 根据你的需求隐藏过滤器
                expanded: false // 确保过滤器不会展开
              },
              urlParams: {
                foo: 'value1',
                bar: 'value2',
              }
            }
          });
        }
      } catch (error) {
        console.error("Error during dashboard embedding:", error);
        // 这里处理错误
      }
    };

    doEmbed();
          
  }, []);


        

  useEffect(() => {
    const updateIframeScale = () => {
      if (iframeRef.current) {
        const isMobile = window.innerWidth <= 768; // 检测是否为移动端设备
        if (isMobile) {
          dashboardContainerRef.current.classList.remove('desktop-center');
          dashboardContainerRef.current.classList.add('mobile-right');
        } else {
          dashboardContainerRef.current.classList.remove('mobile-right');
          dashboardContainerRef.current.classList.add('desktop-center');
        }
      }
    };
    
    window.addEventListener('resize', updateIframeScale);
    updateIframeScale(); // 初始调用以设置缩放

    return () => {
      window.removeEventListener('resize', updateIframeScale); // 组件卸载时清理事件监听器
    };
  }, []); // 空依赖数组表示此effect只在组件挂载时运行一次
    


  return (
    <div ref={dashboardContainerRef} className="dashboard-container" style={{ width: '100%', height: '100vh' }}>
      <iframe
        ref={iframeRef}
        style={{ width: '100%', height: '100%', border: '0', overflow: 'hidden' }}
        sandbox="allow-scripts"
      ></iframe>
    </div>
  );
}

const styles = `
  @media (max-width: 480px) {
    .dashboard-container iframe {
      min-width: 480px; 
    }
  }
`;

// Inject styles into the head of the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default SupersetDashboard2;
