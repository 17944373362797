// ReportList.js
import React, { useState, useEffect } from 'react';
import { Container, Grid, Card, CardContent, CardHeader, Typography} from '@mui/material';
import './ReportList.css';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const ReportList = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [reportsData, setReportsData] = useState([]); // 正确初始化reportsData状态

  useEffect(() => {
    const loggedIn = localStorage.getItem('isLoggedIn') === 'true';
    setIsLoggedIn(loggedIn);

    if (!loggedIn) {
      navigate('/login'); // 如果用户未登录，重定向到登录页面
    }
  }, [navigate]);

  useEffect(() => {
    fetch('https://stardatemine.online:8005/api/reports-categorized')
      .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setReportsData(data); // 使用从API获取的数据更新reportsData状态
      })
      .catch(error => console.error("Fetching reports failed", error));
  }, []);
    
  return (
        <Container maxWidth="md">
          <Grid container spacing={2}>
            {reportsData.map((category, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card sx={{
                  marginBottom: '10px', 
                  marginTop: '10px', 
                  height: '300px', // 使高度自适应内容
                  overflow: 'auto',
                  borderRadius: 2, // 统一圆角大小
                  boxShadow: 3, // 轻微阴影效果增加立体感
                }}>
                  <CardHeader 
                    title={category.category} 
                    titleTypographyProps={{ 
                      align: "center", 
                      variant: "h6",
                      fontWeight: 'bold',
                      fontSize: { xs: '1rem', sm: '1.25rem' }, // 根据屏幕尺寸调整字体大小
                    }} 
                  />
                  <CardContent>
                    {category.reports.length > 0 ? (
                      category.reports.map((report, reportIndex) => (
                            <Typography 
                              key={reportIndex} 
                              variant="body1" 
                              component="p" 
                              sx={{ 
                                marginBottom: '10px',
                                fontSize: { xs: '0.875rem', sm: '1rem' }, // 调整正文字体大小
                              }}>
        
                            <RouterLink 
                              to={`/report/${encodeURIComponent(`https://stardatemine.online:8005${report.url}`)}`} 
                              className="report-link"
                            >
                              {report.name}
                            </RouterLink> 
        
                            </Typography>
                      ))
                    ) : (
                      <Typography 
                        variant="body2"
                        sx={{
                          fontSize: { xs: '0.875rem', sm: '1rem' }, // 调整无报告提示的字体大小
                        }}>
                        此分类下无报告
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
  );
};

export default ReportList;
